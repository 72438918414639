import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FixedTooltip from './FixedTooltip';

const PORTAL_MOUNT_EL = window.document.body;

export default function FixedTooltipPortal({
  isVisible,
  className,
  wrapperClassName,
  onCloseClick,
  children,
  style,
  shouldHideCloseButton,
  portalMountRef
}) {
  useEffect(() => {
    isVisible
      ? PORTAL_MOUNT_EL.classList.add('no-scroll')
      : PORTAL_MOUNT_EL.classList.remove('no-scroll');
  }, [isVisible]);

  if (portalMountRef?.current === null) return null;

  return createPortal(
    <>
      {isVisible && (
        <div
          role="button"
          tabIndex="-1"
          onKeyPress={null}
          onClick={onCloseClick}
          className="fixed bg-black-70 absolute--fill z-max"
        />
      )}
      <div
        data-test-id="tooltip"
        className={classnames(
          'FixedTooltipWrapper__tooltip z-max fixed transition bottom-0 left-0 right-0',
          wrapperClassName,
          {
            'FixedTooltipWrapper__tooltip--hidden': !isVisible
          }
        )}
      >
        <FixedTooltip
          className={className}
          onCloseClick={onCloseClick}
          title={children}
          style={style}
          shouldHideCloseButton={shouldHideCloseButton}
        />
      </div>
    </>,
    portalMountRef?.current || PORTAL_MOUNT_EL
  );
}

FixedTooltipPortal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  shouldHideCloseButton: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  portalMountRef: PropTypes.object
};

FixedTooltipPortal.defaultProps = {
  className: null,
  wrapperClassName: null,
  shouldHideCloseButton: false,
  style: null,
  portalMountRef: {}
};
