import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';

export default function FixedTooltip({
  className,
  title,
  onCloseClick,
  style,
  shouldHideCloseButton
}) {
  return (
    <div
      className={classnames(
        'FixedTooltip bg-white br5 br--top ph4 pt3 pb8 ba b--light-gray',
        className
      )}
      role="tooltip"
      style={{
        maxHeight: '100vh',
        minHeight: '3rem',
        overflow: 'scroll',
        ...style
      }}
    >
      {!shouldHideCloseButton && (
        <button
          type="button"
          onClick={onCloseClick}
          className="absolute top-1 right-1 black-50 z-999"
          data-test-id="close-button"
        >
          <Icon
            name="close"
            className="ph2 pv2 ba b--light-gray br-100 bg-white"
            height="32px"
            width="32px"
          />
        </button>
      )}
      {title}
    </div>
  );
}

FixedTooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  shouldHideCloseButton: PropTypes.bool
};

FixedTooltip.defaultProps = {
  className: null,
  style: null,
  shouldHideCloseButton: false
};
