import { isElementHidden } from 'dpl/shared/utils';

const BREAKPOINT_TO_CLASSNAMES = {
  xs: 'd-block d-sm-none',
  sm: 'd-none d-sm-block d-md-none',
  md: 'd-none d-md-block d-lg-none',
  lg: 'd-none d-lg-block d-xl-none',
  xl: 'd-none d-xl-block'
};

const ORDERED_BREAKPOINTS = Object.keys(BREAKPOINT_TO_CLASSNAMES);

const IS_NODE =
  typeof process === 'object' &&
  typeof process.versions === 'object' &&
  typeof process.versions.node !== 'undefined';

export const getCurrentBreakpoint = (() => {
  let containerEl;
  let currentGridSize;

  function appendElements() {
    const _containerEl = document.createElement('span');

    Object.entries(BREAKPOINT_TO_CLASSNAMES).forEach(
      ([breakpoint, className]) => {
        const childEl = document.createElement('span');
        childEl.className = className;
        childEl.dataset.bootstrapGridSize = breakpoint;
        _containerEl.appendChild(childEl);
      }
    );

    Object.assign(_containerEl.style, {
      visibility: 'hidden',
      overflow: 'hidden',
      fontSize: '0',
      height: '0',
      position: 'absolute',
      left: '-9999px',
      top: '-9999px'
    });

    document.body.appendChild(_containerEl);

    return _containerEl;
  }

  function bindResizeHandler() {
    function handleResize() {
      currentGridSize = null;
      window.removeEventListener('resize', handleResize);
    }

    window.addEventListener('resize', handleResize);
  }

  return () => {
    if (IS_NODE) {
      return 'lg';
    }

    if (!containerEl) {
      containerEl = appendElements();
    }

    if (!currentGridSize) {
      currentGridSize = [].find.call(
        containerEl.children,
        child => !isElementHidden(child)
      )?.dataset?.bootstrapGridSize;

      bindResizeHandler();
    }

    return currentGridSize;
  };
})();

export function isBreakpoint(breakpoint) {
  return getCurrentBreakpoint(breakpoint) === breakpoint;
}

export function isGreaterThanBreakpoint(breakpoint) {
  return (
    ORDERED_BREAKPOINTS.indexOf(getCurrentBreakpoint()) >
    ORDERED_BREAKPOINTS.indexOf(breakpoint)
  );
}

export function isLessThanBreakpoint(breakpoint) {
  return (
    ORDERED_BREAKPOINTS.indexOf(getCurrentBreakpoint()) <
    ORDERED_BREAKPOINTS.indexOf(breakpoint)
  );
}
